
import { defineComponent } from "vue";

export default defineComponent({
  name: 'Contact',
  data() {
    return {
      contactEmail: 'contact@psautierdesaintelucie.fr',
      contactLink: 'mailto:contact@psautierdesaintelucie.fr',
    };
  },

  methods: {
    contactByEmail () {
      window.location.href = this.contactLink;
    },
  },
});
